interface ITeams {
  Id: string;
  tImg: string;
  name: string;
  title: string;
  linkedinProfilePath?: string;
}

const Teams: ITeams[] = [
  {
    Id: "1",
    tImg: '',
    name: "Franco Scaravaggi",
    title: "Chief Executive Officer",
    linkedinProfilePath: "https://www.linkedin.com/in/franco-scaravaggi-913936119/",
  },
  {
    Id: "2",
    tImg: '',
    name: "Marzia Scaravaggi",
    title: "Chief Financial Officer",
    linkedinProfilePath: "https://www.linkedin.com/in/marzia-scaravaggi-12b15649/",
  },
  {
    Id: "3",
    tImg: '',
    name: "Giuseppe De Rosa",
    title: "Vice President",
    linkedinProfilePath: "https://www.linkedin.com/in/giuseppe-d-285a5b101/",
  },
  {
    Id: "4",
    tImg: '',
    name: "Manuel Scaravaggi",
    title: "Chief Operation Officer",
    linkedinProfilePath: "",
  },
  {
    Id: "5",
    tImg: '',
    name: "Vincenzo Gattei",
    title: "IT Manager",
    linkedinProfilePath:
      "https://www.linkedin.com/in/vincenzo-gattei-99350b32/",
  },
];

export default Teams;
