import React from 'react';
import sedi from '../../api/sedi.ts'
import { Link } from 'react-router-dom';
import { ClickHandler } from '../../Utils/functions.js';

const Sedi = () => {

    return (
        <section className="policy_section bg-light pt-4">
            <div className="container">
                <div className="heading_block">
                    <h2 className="heading_text text-center">
                        I nostri impianti
                    </h2>
                </div>
                <div className="row">
                    {sedi.map((sede, i) => (
                        <div className="col-lg-4" key={i}>
                            <div className="iconbox_block">
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">
                                        {sede.title}
                                    </h3>
                                    <p className="mb-0">
                                        {sede.indirizzo}
                                    </p>
                                </div>
                                <div>
                                    <Link to={'our-location'} onClick={ClickHandler} className="icon_block">
                                        <i className="fa-regular fa-arrow-up-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Sedi;