import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import logo from '../../images/site_logo/Logo.png'
import { ClickHandler } from '../../Utils/functions';
import { menuItems } from '../../api/menuItems.ts';

const Header2 = () => {

    const [mobailActive, setMobailState] = useState(false);
    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <header className="site_header site_header_2">
            <div className={`header_bottom stricky  ${isSticky ? 'stricked-menu stricky-fixed' : ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-2 col-5 ">
                            <div className="site_logo">
                                <Link onClick={ClickHandler} className="site_link" to="/">
                                    <img src={logo} alt="TILOG" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-2">
                            <nav className="main_menu navbar navbar-expand-lg">
                                <div className="main_menu_inner collapse navbar-collapse justify-content-lg-center" id="main_menu_dropdown">
                                    <ul className="main_menu_list unordered_list justify-content-center">
                                        {menuItems.map((item, i) => (
                                            <li key={i} className={`${item.submenu && 'dropdown'}`}>
                                                <Link to={`${item.link}`} onClick={ClickHandler} id={item.idItem} className="nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">{item.title}</Link>
                                                {item.submenu && (
                                                    <ul className="dropdown-menu" aria-labelledby={item.idItem}>
                                                        {item.submenu.map((submenu, i) => (
                                                            <li key={i}>
                                                                {item.target === 'blank' ? <a onClick={ClickHandler} href={submenu.link} target="_blank" rel="noreferrer">{submenu.title}</a> : <Link onClick={ClickHandler} to={submenu.link}>{submenu.title}</Link>}
                                                            </li>
                                                        ))}

                                                    </ul>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-5">
                            <ul className="header_btns_group unordered_list justify-content-end">
                                <li>
                                    <button className="mobile_menu_btn" onClick={() => setMobailState(!mobailActive)} type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="far fa-bars"></i>
                                    </button>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mobail-wrap">
                    <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
                        <div className="xb-header-menu-scroll">
                            <div className="xb-menu-close xb-hide-xl xb-close" onClick={() => setMobailState(!mobailActive)}></div>
                            <nav className="xb-header-nav">
                                <MobileMenu />
                            </nav>
                        </div>
                    </div>
                    <div className="xb-header-menu-backdrop" onClick={() => setMobailState(false)}></div>
                </div>
            </div>
        </header>

    )
}

export default Header2;