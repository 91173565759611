
const ImageSection = (props) => {

    return (
        <section className="calltoaction_section parallaxie" style={{ backgroundImage: `url(${props.img})` }}>
            <div className="container text-center">
                <div className="heading_block text-white">
                    <h2 className="heading_text">
                        {props.title}
                    </h2>
                </div>
            </div>
        </section>
    );
}

export default ImageSection;