interface IFunfact {
  countEnd: number;
  title: string;
  bgColor: string;
  description: string;
}
//! 3 oggetti, massimo 4
//! no numeri con la virgola
const funfact: IFunfact[] = [
  {
    countEnd: 100,
    title: "Sicurezza sul lavoro",
    bgColor: "bg-primary",
    description:
      "Monitoraggio della sicurezza, riduzione degli incroci pericolosi e monitoraggio dei carichi di lavoro",
  },
  {
    countEnd: 55,
    title: "Ottimizzazione processi",
    bgColor: "bg-danger",
    description: "",
  },
  {
    countEnd: 60,
    title: "Saving",
    bgColor: "bg-secondary",
    description: "",
  },
  {
    countEnd: -70,
    title: "Green technology",
    bgColor: "bg-warning",
    description: "",
  },
];

export default funfact;
