import React from 'react';
import { vantaggi } from '../../api/vantaggi.ts';
import imgCarrello from '../../images/about-us/carrello.jpg'

const BestService = () => {

    return (
        <section className="service_section section_space bg-light pb-5">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="image_wrap">
                            <img src={imgCarrello} alt="Migliori servizi" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ps-lg-5">
                            <div className="heading_block">
                                <h2 className="heading_text mb-0">
                                    Perch&egrave; i nostri servizi sono migliori
                                </h2>
                            </div>
                            <ul className="service_facilities_group unordered_list">
                                {vantaggi.map((vntg, pitem) => (
                                    <li key={pitem}>
                                        <strong className="iconbox_block layout_icon_left">
                                            <span className="iconbox_icon">
                                                <img src={vntg.icon} alt="Dollar SVG Icon" />
                                            </span>
                                            <span className="iconbox_content">
                                                <strong className="iconbox_title mb-0">{vntg.title}</strong>
                                            </span>
                                        </strong>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BestService;