import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import technologyService from '../../api/technologyService.ts'
import GetSettingsSlider from '../../Utils/SettingsSlider.js'

const settings = GetSettingsSlider(technologyService)

const ListServiziIt = () => {

    return (
        <div className="feature_partners_section">
            <div className="container position-relative">
                <div className="title_text text-white">
                    I nostri servizi IT
                </div>
                <div className="client_logo_carousel">
                    <Slider {...settings}>
                        {technologyService.map((service, i) => (
                            <a href={service.href} key={i} title={service.title} target="_blank" rel="noreferrer">
                                <div className="client_logo_item">
                                    <img src={service.img} alt={service.alt} />
                                </div>
                            </a>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default ListServiziIt;