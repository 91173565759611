import { Link } from 'react-router-dom'
import services from '../../api/service.ts'
import Bg from '../../images/backgrounds/bg_image_2.png'
import Bg3 from '../../images/shapes/shape_title_under_line.svg'
import shape4 from '../../images/shapes/shape_space_4.svg'
import { ClickHandler } from '../../Utils/functions.js';


const Services = () => {

  return (
    <section className="about_and_case_section section_space section_decoration bg-dark" style={{ backgroundImage: `url(${Bg})` }}>
      <div className="container">
        <div>
          <div className="heading_block text-center text-white">
            <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg3})` }}>
              Cosa offriamo
            </div>
            <h2 className="heading_text mb-0">
              I nostri <mark>Servizi</mark>
            </h2>
          </div>

          <div className="case_studies_wrapper">
            {services.map((service, i) => (
              <div className="case_study_block py-3 justify-content-around" key={i}>
                <div className="case_study_image mx-1">
                  <img src={service.img} alt={service.alt} />
                </div>
                <div className="case_study_content">
                  <ul className="category_list unordered_list text-uppercase">
                    <li><a href="portfolio.html">{service.sub}</a></li>
                  </ul>
                  <h3 className="case_title">
                    {service.title}
                  </h3>
                  <p>
                    {service.shortDescription}
                  </p>
                  <Link onClick={ClickHandler} to={`/service/${service.Id}`} className="btn btn-primary">
                    <span className="btn_label" data-text="Leggi di pi&ugrave;">Leggi di pi&ugrave;</span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="btns_group pb-0">
            <Link onClick={ClickHandler} to="/service" className="btn btn-primary">
              <span className="btn_label" data-text="Guarda tutti i servizi">Guarda tutti i servizi</span>
              <span className="btn_icon">
                <i className="fa-solid fa-arrow-up-right"></i>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="decoration_item shape_image_1">
        <img src={shape4} alt="Techco Shape" />
      </div>
    </section>
  )
}

export default Services;