import React from 'react'
import ContactForm from '../ContactFrom/ContactForm'
import icon1 from '../../images/icons/icon_map_mark_2.svg'
import icon2 from '../../images/icons/icon_calling_2.svg'
import icon3 from '../../images/icons/icon_mail_3.svg'
import icon4 from '../../images/icons/icon_calendar_2.svg'
import { emailOspitaletto, nTelefonoOspitaletto } from '../../api/contattiSedi'


const ContactSection = () => {
    return (
        <section className="contact_section section_space bg-light">
            <div className='container text-center'>
                <h1 className="page_title mb-5 text-black">Contattaci</h1>
            </div>
            <div className="container">
                <div className="contact_info_box row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon1} alt="Map Mark SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Vieni a trovarci</h3>
                                <p className="mb-0">
                                    Via Giovanni Falcone, 14, 25035 Ospitaletto BS
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon2} alt="Calling SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Il nostro numero</h3>
                                <p className="mb-0">{nTelefonoOspitaletto}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon3} alt="User Check SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Email</h3>
                                <p className="mb-0">{emailOspitaletto}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon4} alt="Calendar SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">I nostri orari di apertura</h3>
                                <p className="mb-0">Lun - Ven: 08.00-18.00</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_space pb-0">
                    <div className='text-center mb-5'>
                        <h1 className=''>
                            <mark>
                                Scrivici
                            </mark>
                            &#160; per avere maggiori informazioni</h1>
                    </div>
                    <div className="row justify-content-lg-center">
                        <div className="col-start-4 col-lg-8">
                            <div className="contact_form mb-0">
                                <ContactForm />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactSection;