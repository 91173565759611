import sIcon1 from '../images/icons/icon_check_2.svg'
import sIcon2 from '../images/icons/icon_leaf.svg'
import sIcon3 from '../images/icons/icon_box.svg'
import sIcon4 from '../images/icons/icon_receipt_add.svg'
import sIcon5 from '../images/icons/icon_monitor.svg'
// import sIcon6 from '../images/icons/icon_microscope.svg'

interface IVantaggi {
    title: string;
    icon:string;
}

export const vantaggi: IVantaggi[] = [
    {
        title: 'Qualità viene prima',
        icon: sIcon1,
    },
    {
        title: 'Cooperazione',
        icon: sIcon2,
    },
    {
        title: 'Consegne in tempo',
        icon: sIcon3,
    },
    {
        title: 'Costi trasparenti',
        icon: sIcon4,
    },
    {
        title: 'Personale qualificato',
        icon: sIcon5,
    }
]