import sImg1 from "../images/servizi/supplay-chain.jpg";
import sImg2 from "../images/servizi/software-developmentjpg.jpg";
import sImg3 from "../images/servizi/Consulente logistico direzionale.jpg";
import sImg4 from "../images/servizi/Transport contract managment.jpg";
// import sImg5 from "../images/services/service_image_5.webp";
// import sImg6 from "../images/services/service_image_6.webp";
// import sImg7 from "../images/services/service_image_7.webp";
// import sImg8 from "../images/services/service_image_8.webp";

import icon1 from "../images/icons/icon_code.svg";
import icon2 from "../images/icons/icon_programming_tree.svg";
import icon3 from "../images/icons/icon_monitor_2.svg";
import icon4 from "../images/icons/icon_phone.svg";
// import icon5 from "../images/icons/icon_bug.svg";
// import icon6 from "../images/icons/icon_programming.svg";

// import icon7 from "../images/icons/icon_analisis_2.svg";
// import icon8 from "../images/icons/icon_process.svg";
// import icon9 from "../images/icons/icon_chart_2.svg";
// import icon10 from "../images/icons/icon_meeting.svg";
// import icon11 from "../images/icons/icon_bulb_2.svg";
// import icon12 from "../images/icons/icon_speed_miter.svg";

interface IService {
  Id: string;
  img: string;
  alt: string;
  icon: string;
  title: string;
  description: string;
  shortDescription: string;
  features: string[];
}

const Services: IService[] = [
  {
    Id: "s1",
    img: sImg1,
    alt: "Supplay chain managment",
    title: "Supplay chain managment",
    features: ["Vantaggio 1", "Vantaggio 2", "Vantaggio 3", "Vantaggio 4"],
    icon: icon1,
    description:
      "L’infrastruttura globale di TILOG Supply Chain Solutions fornisce i servizi di gestione della catena di approvvigionamento scalabili ed efficienti. Questo modello di gestione si basa su servizi operativi che utilizzano processi configurabili, ripetibili e comprovati per fornire all’utente servizi globali end-to-end. Le Control Tower TILOG offrono l’esecuzione delle operazioni della Supply Chain del cliente , incentrate sulla fornitura di servizi end-to-end  3PL e 2PL.",
    shortDescription:
      "Un team di esperti capace di identificare opportunit° per migliorare la vostra supply chain",
  },
  {
    Id: "s2",
    img: sImg2,
    alt: "Software development",
    title: "Software development",
    features: ["Vantaggio 1", "Vantaggio 2", "Vantaggio 3"],
    icon: icon2,
    description:
      "Forniamo ai bisogni e alle necessità dei nostri clienti un dipartimento interno a loro dedicato per risolvere ogni difficoltà di data entry o comunicazione legati al mondo della logistica",
    shortDescription:
      "Un dipartimento interno che realizza soluzioni logistiche software taylor made",
  },
  {
    Id: "s3",
    img: sImg3,
    alt: "Consulente logistico direzionale",
    title: "Consulente logistico direzionale",
    features: ["Vantaggio 1", "Vantaggio 2", "Vantaggio 3", "Vantaggio 4"],
    icon: icon3,
    description:
      "Il nostro team di consulenza logistica è supervisionato da un manager con oltre vent'anni di esperienza e di impegno quotidiano per risolvere dopo aver identificato le principali complessità che il mondo logistico propone",
    shortDescription:
      "Offriamo un servizio di consulenza direnzionale gestire anche i più complessi problemi logistici",
  },
  {
    Id: "s4",
    img: sImg4,
    alt: "Transport contract managment",
    title: "Transport contract managment",
    features: ["Vantaggio 1", "Vantaggio 2", "Vantaggio 3", "Vantaggio 4"],
    icon: icon4,
    description:
      "Gestire al meglio e ottimizzare i propri trasporti è ad oggi un obbligo oltre che di rispetto verso il pianeta per limitare le emissioni una necessità per offrire un servizio eccellente ai propri clienti senza dimenticare la necessità di offrire un rapporto qualità prezzo sempre molto conveniente. Ecco perché abbiamo al servizio dei nostri clienti un team di esperti supportati da un software di ottimizzazione dei trasporti che sta recentemente facendo sempre passi maggiori nell'utilizzo dei sistemi di apprendimento e dell'intelligenza artificiale",
    shortDescription:
      "Offriamo un servizio di ottimizzazione dei trasporti con una control Tower di monitoraggio continuo",
  },
];

export default Services;
