import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer.js';
import BestService from '../../components/bestService/BestService.js';
import services from '../../api/service.ts';
import ServiceTab from '../../components/ServiceTab/ServiceTab.js'

const ServicePage = () => {
    return (
        <>
            <Header />
            <main className="page_content about-page">
                <ServiceTab />
                <section className="about_section pt-5 bg-light">
                    <div className="container">
                        {services.map((service, i) => (
                            <div className={`row align-items-center justify-content-lg-between py-3 ${i % 2 === 0 && 'row-reverse'} `} key={i} id={service.Id}>
                                <div className={`col-lg-5 order-lg-last `}>
                                    <div className="team_cartoon_image">
                                        <img src={service.img} alt={service.alt} />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="about_content">
                                        <div className="heading_block">
                                            <h2 className="heading_text">
                                                {service.title}
                                            </h2>
                                            <p className="heading_description mb-0">
                                                {service.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <BestService />
            </main>
            <Footer />
            <Scrollbar />
        </>
    )
};
export default ServicePage;
