import Header2 from '../../components/Header2/Header2';
import Hero from '../../components/hero/hero';
import ListServiziIt from '../../components/listServiziIt/listServiziIt.js';
import Services from '../../components/services/Services.js';
import Workflow from '../../components/workflow/Workflow.js';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ContactSection from '../../components/ContactSection';
import Footer from '../../components/footer/Footer';
import Sedi from '../../components/sedi/Sedi'
import ContactItem from '../../components/contactItem/ContactItem.js';
import Video from '../../components/videoSection/Video.js';
import { useEffect } from 'react';
import { URL } from '../../Utils/varContainer.js';

const HomePage = () => {

    useEffect(() => {
        async function t() {
            const response = await fetch(`${URL}/Home/linkedin/posts`);
            console.log(response);
            const data = await response.json();
            console.log(data);
            try {
                if (response.ok) {

                    console.log(data);
                } else {
                    console.error('Errore nel recupero dei post:', response.statusText);
                }
            } catch (error) {
                console.error(error);

            }

        }
        t()
    }, [])

    return (
        <>
            <div>
                <Header2 />
                <main className="page_content">
                    <Hero />
                    <ListServiziIt />
                    <Video />
                    <Services />
                    <Workflow />
                    <Sedi />
                    <ContactSection />
                    <ContactItem />
                </main>
                <Footer />
                <Scrollbar />
            </div>
        </>
    )
};
export default HomePage;
