interface IWorkflow {
  id: string;
  title: string;
  description: string[];
}

export const workflow: IWorkflow[] = [
  {
    id: "1",
    title: "Ricevimento Merci",
    description: [
      "Controllo quantitativo e qualitativo delle merci in arrivo.",
      "Registrazione nel sistema informatico di magazzino.",
      "Assegnazione di un luogo di stoccaggio.",
    ],
  },
  {
    id: "2",
    title: "Stoccaggio",
    description: [
      "Posizionamento delle merci negli scaffali o pallettizzati a seconda delle caratteristiche del prodotto e dello spazio disponibile.",
      "Gestione dell'inventario per garantire la tracciabilità delle merci.",
    ],
  },
  {
    id: "3",
    title: "Ordini e Preparazione",
    description: [
      "Ricezione degli ordini dai clienti.",
      "Prelievo delle merci dagli scaffali secondo l'ordine.",
      "Imballaggio e etichettatura dei prodotti.",
    ],
  },
  {
    id: "4",
    title: "Spedizione",
    description: [
      "Verifica della correttezza dell'ordine e della documentazione.",
      "Caricamento delle merci sui mezzi di trasporto.",
      "Comunicazione al cliente della spedizione.",
    ],
  },
  {
    id: "5",
    title: "Gestione delle Restituzioni",
    description: [
      "Ricevimento e registrazione delle merci restituite.",
      "Controllo dello stato delle merci e gestione delle eventuali sostituzioni o rimborsi.",
    ],
  },
];
