import Header from '../../components/header/Header';
import CountUp from 'react-countup';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import funfact from '../../api/funfact.ts';
import BestService from '../../components/bestService/BestService.js';
import { settori } from '../../api/settori.ts';
import magazzino from '../../images/about-us/magazzino.jpg'

const AboutUs = () => {
    return (
        <>
            <Header />
            <main className="page_content about-page">
                <section className="team_details_section pt-4 bg-light">
                    <div className="container">
                        <div className=''>
                            <div className="pb-5">
                                <div className="image_wrap position-relative">
                                    <div className='text-center'>
                                        <h3 className="details_item_info_title badge bg-secondary">TILOG</h3>
                                    </div>
                                    <img src={magazzino} alt="Techco - About" />
                                </div>
                            </div>
                            <div>
                                <p>
                                    TILOG è un provider di servizi specializzati nell’ambito della grande industria per qualsiasi tipo di settore. Siamo orgogliosi di fornire le migliori soluzioni di logistica integrata, trasporto, imballaggio e manutenzione di impianti e macchinari. Il nostro personale qualificato, utilizza i più moderni sistemi di gestione, monitoraggio controllo ed elaborazione, combinato con decenni di esperienza!

                                </p>
                                <p>
                                    Riteniamo che i servizi offerti dalla nostra azienda debbano soddisfare le elevate aspettative dei nostri clienti. Siamo impegnati a creare valore aggiunto per i nostri clienti implementando la tecnologia moderna nel nostro lavoro. Ecco perché il desiderio di un costante miglioramento è la forza trainante della nostra attività.

                                    Fornire i migliori servizi attualmente disponibili ecco la nostra Mission. Il nostro personale qualificato, utilizza le Tecnologie più recenti, combinate con decenni di esperienza!
                                </p>
                            </div>
                        </div>
                        <div className="row my-5">
                            {funfact.map((card, i) => (

                                <div className="col-lg-3 col-md-6 col-sm-6" key={i}>
                                    <div className="funfact_block text-center" title={card.description}>
                                        <div className="counter_value">
                                            <span className="odometer" data-count={card.countEnd}><CountUp end={card.countEnd} enableScrollSpy /></span>
                                            <span>%</span>
                                        </div>
                                        <h3 className="funfact_title mb-0">{card.title}</h3>
                                        <div className={`bottom_line ${card.bgColor}`}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <h3 className="details_item_info_title">Settori logistica</h3>
                        <p>
                            Lavoriamo in diversi settori
                        </p>
                        <ul className="icon_list unordered_list_block">
                            {settori.map((settore, i) => (
                                <li key={i}>
                                    <span className="icon_list_icon">
                                        <i className="fa-solid fa-circle fa-fw"></i>
                                    </span>
                                    <span className="icon_list_text">
                                        {settore.description}
                                    </span>
                                </li>
                            ))}
                        </ul>
                        <div>
                            <BestService />
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <Scrollbar />
        </>
    )
};
export default AboutUs;