import { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import services from '../../api/service.ts';
import { useNavigate, useParams } from 'react-router-dom';

const TechnologySection = () => {

    const [activeTab, setActiveTab] = useState('');
    const [service, setService] = useState();
    const paramId = useParams();
    const navigate = useNavigate();

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        setService(document.getElementById(paramId.id))
        if (service !== undefined && service !== null)
            service.scrollIntoView({ block: "center" });

        if (activeTab !== paramId.id) setActiveTab(paramId.id);
    }, [paramId.id, service, activeTab])


    function scroll(id) {
        navigate(`/service/${id}`)
        const service = document.getElementById(id)
        if (service !== null)
            service.scrollIntoView({ block: "center" })
    }

    return (
        <div className="tab_block_wrapper mb-3">
            <Nav tabs className="nav justify-content-center">
                {services.map((service, i) => (

                    <NavItem key={i} onClick={() => { scroll(service.Id) }}>
                        <NavLink
                            className={classnames({ active: activeTab === service.Id })}
                            onClick={() => { toggle(service.Id); }}>
                            {service.title}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>


        </div>
    )
}

export default TechnologySection;