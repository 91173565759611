import img1 from "../images/servizi_it/booking.png";
import img2 from "../images/servizi_it/mobile chat.png";
import img3 from "../images/servizi_it/mobile suite.png";
import img4 from "../images/servizi_it/reporting.png";
import img6 from "../images/servizi_it/richiestilog.png";

interface ITechnologyService {
  img: string;
  alt: string;
  title: string;
  href: string;
}

const technologyService: ITechnologyService[] = [
  {
    img: img1,
    alt: "booking",
    title: "Il tuo sito per le prenotazioni",
    href: "https://booking.tilog.it/",
  },
  {
    img: img2,
    alt: "mobilechat",
    title: "Chatta con i tuoi colleghi",
    href: "https://mobilechat.tilog.it/",
  },
  {
    img: img3,
    alt: "mobilesuite",
    title: "Gestisci tutto",
    href: "https://mobilesuite.tilog.it/",
  },
  {
    img: img4,
    alt: "reporting",
    title: "Controlla tutto",
    href: "https://reporting.tilog.it/",
  },
  {
    img: img6,
    alt: "richiestilog",
    title: "Manda richieste",
    href: "https://richies.tilog.it/",
  },
];

export default technologyService;
