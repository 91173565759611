import Teams from '../../api/team.ts'
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import sImg3 from '../../images/icons/icon_linkedin.svg'
import defaultUserImg from '../../images/team/user.png'

const TeamPage = () => {

    return (
        <>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Leadership Team'} />
                <section className="team_section section_space">
                    <div className="container">
                        <div className="heading_block text-center">
                            <div className="heading_focus_text">
                                <h2 className="heading_text mb-0 badge bg-secondary">
                                    I nostri esperti
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                            {Teams.map((team, i) => (
                                <div className="col-lg-4 col-md-6 col-sm-6" key={i}>
                                    <div className="team_block">
                                        <div className='image_container'>
                                            <img id='image_team' src={team.tImg.length > 0 ? team.tImg : defaultUserImg} alt={team.name} />
                                        </div>
                                        <div className="team_member_info mt-3">
                                            <h3 className="team_member_name">
                                                {team.name}
                                            </h3>
                                            <h4 className="team_member_designation">{team.title}</h4>
                                            <ul className="social_icons_block unordered_list justify-content-center">
                                                {team.linkedinProfilePath &&
                                                    <li>
                                                        <a href={`${team.linkedinProfilePath}`} target='_blank' rel="noopener noreferrer">
                                                            <img src={sImg3} alt="Icon Linkedin" />
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <Scrollbar />
        </>
    )
};
export default TeamPage;
