
import  { useState } from 'react';
import Bg from '../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../images/shapes/shape_line_2.svg'
import shape2 from '../../images/shapes/shape_line_3.svg'
import shape4 from '../../images/shapes/shape_space_3.svg'
import { workflow } from '../../api/workflow.ts';

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const Workflow = () => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="process_technology_review_section bg-light section_decoration">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="heading_block">
                            <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                Working Process
                            </div>
                            <h2 className="heading_text mb-0">
                                Il nostro <mark>Flusso</mark> di lavoro
                            </h2>
                        </div>
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            {workflow.map((flow, i) => (
                                <AccordionItem className="accordion-item" key={i}>
                                    <AccordionHeader targetId={flow.id}>
                                        {flow.title}
                                    </AccordionHeader>
                                    <AccordionBody accordionId={flow.id} className='acc_body'>
                                        <ul>
                                            {flow.description.map((d, i) => (
                                                <li key={i}>{d}</li>
                                            ))}
                                        </ul>
                                    </AccordionBody>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                    <div className="col-lg-5">
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <ul className="content_layer_group unordered_list_block text-center">
                                {workflow.map((flow, i) => (
                                    <li key={i}><AccordionHeader targetId={flow.id}><span>{flow.title}</span></AccordionHeader></li>
                                ))}
                            </ul>
                        </Accordion>
                    </div>
                </div>
            </div>

            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_4">
                <img src={shape4} alt="Techco Shape" />
            </div>
        </section>
    )
};
export default Workflow;
