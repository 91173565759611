import React from 'react';
import sIcon1 from '../../images/icons/icon_user_check.svg'
import sIcon2 from '../../images/icons/icon_headphone.svg'
import { Link } from 'react-router-dom';
import { ClickHandler } from '../../Utils/functions';
import shape3 from '../../images/shapes/shape_space_2.svg'

const Contact = [
    {
        title: 'Contattaci',
        subTitle: 'Contattaci per sapere qualcosa',
        link: '/contact',
        icon: sIcon1,
        alt: 'Contattaci'
    },
    {
        title: 'Lavora con noi',
        subTitle: 'Saremo lieti di ricevere il tuo CV',
        link: '/hiring',
        icon: sIcon2,
        alt: 'Lavora con noi'
    },
]


const ContactItem = () => {

    return (
        <>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="Techco Shape" />
            </div>
            <section className="policy_section py-5">
                <div className="container">
                    <div className="row">
                        {Contact.map((item, i) => (
                            <div className={`col-lg-${12 / Contact.length}`} key={i}>
                                <div className="iconbox_block layout_icon_left">
                                    <div className="iconbox_icon bg-secondary-subtle">
                                        <img src={item.icon} alt={item.alt} />
                                    </div>
                                    <div className="iconbox_content">
                                        <h3 className="iconbox_title">
                                            <Link to={item.link} onClick={ClickHandler}>
                                                {item.title}
                                            </Link>
                                        </h3>
                                        <p className="mb-0">
                                            {item.subTitle}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </section>
        </>
    );
}

export default ContactItem;