import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import HomePage from '../HomePage/HomePage';
import OurLocation from '../OurLocation/OurLocation';
import TeamPage from '../TeamPage/TeamPage';
import AboutUs from '../AboutUs/AboutUs';
import ServicePage from '../ServicePage/ServicePage';
import ContactPage from '../ContactPage/ContactPage';
import Hiring from '../Hiring/Hiring';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="our-location" element={<OurLocation />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="service/:id" element={<ServicePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="hiring" element={<Hiring />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
