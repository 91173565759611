import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import ContactSection from '../../components/ContactSection';

const ContactPage = () => {

    return (
        <>
            <Header />
            <main className="page_content about-page">
                <ContactSection />
            </main>
            <Footer />
            <Scrollbar />
        </>
    )
};

export default ContactPage;