import HiringForm from '../HiringForm/HiringForm'

const HiringSection = () => {
    return (
        <section className="contact_section  bg-light">

            <div className="container">
                <h3 className="details_item_info_title pt-4 text-center">Inviaci il tuo CV</h3>
                <p>
                    Siamo lieti di ricevere la tua candidatura
                </p>
                <div className="section_space pb-0">
                    <div className="row justify-content-lg-between">
                        <div className="col-lg-12">
                            <div className="contact_form mb-0">
                                <HiringForm />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default HiringSection;