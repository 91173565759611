import sedi from '../../api/sedi.ts';
import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import { ClickHandler } from '../../Utils/functions'


const OurLocation = () => {

    return (
        <>
            <Header />
            <main className="page_content about-page">
                <section className="portfolio_section py-5 bg-light">
                    <div className='container text-center'>
                        <h1 className="page_title mb-5 text-black">Le nostre sedi</h1>
                    </div>
                    <div className="container">
                        <div className="filter_elements_wrapper row">
                            {sedi.map((project, prj) => (
                                <div className="col-lg-6" key={prj}>
                                    <div className="portfolio_block portfolio_layout_2">
                                        <div className="portfolio_image">
                                            <div onClick={ClickHandler} className="portfolio_image_wrap bg-light" >
                                                <img src={project.pImg} alt={project.alt} />
                                            </div>
                                        </div>
                                        <div className="portfolio_content">
                                            <h3 className="portfolio_title">
                                                <div onClick={ClickHandler} >
                                                    {project.title}
                                                </div>
                                            </h3>
                                        </div>
                                        <div className='portfolio_content'>
                                            {project.telefono && <div className='d-flex gap-4'>
                                                <h6>Telefono</h6>
                                                <p>{project.telefono}</p>
                                            </div>}
                                            {project.email && <div className='d-flex gap-4'>
                                                <h6>Email</h6>
                                                <p>{project.email}</p>
                                            </div>}
                                            {project.indirizzo && <div className='d-flex gap-4'>
                                                <h6>Indirizzo</h6>
                                                <p>{project.indirizzo}</p>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <Scrollbar />
        </>
    )
};

export default OurLocation;
