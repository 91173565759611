import { toast } from "react-toastify";
import { URL } from "../Utils/varContainer";

export async function sendEmail(emailData) {

    const res = await fetch(`${URL}/Home/sendEmail`, {
        method: 'POST',
        body: JSON.stringify(emailData),
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    })
    if (res.ok) {
        const data = await res.text()
        toast.success(data)
    }
    else {
        console.error(res);

    }
}