import services from "./service.ts";
import codiceEticoPDF from '../images/pdf/Codice-etico-1.pdf';
import parteSpecUnoPDF from '../images/pdf/PARTE-SPECIALE-1.pdf';
import parteSpecduePDF from '../images/pdf/PARTE-SPECIALE-2.pdf';
import AspettiGeneraliPDF from '../images/pdf/MOG-231-ASPETTI-GENERALI.pdf';

interface IMenuItems {
  id: number;
  title: string;
  link?: string;
  idItem: string;
  target?: string;
  submenu?: Array<{
    id: string;
    title: string;
    link: string;
  }>;
}

export const menuItems: IMenuItems[] = [
  {
    id: 1,
    title: "Home",
    link: "/",
    idItem: "home_submenu",
  },
  {
    id: 3,
    title: "Chi siamo",
    link: "/about-us",
    idItem: "about_submenu",
    submenu: [
      {
        id: "31",
        title: "TILog",
        link: "/about-us",
      },
      {
        id: "3222",
        title: "Le nostre sedi",
        link: "/our-location",
      },
      {
        id: "125",
        title: "Team",
        link: "/team",
      },
    ],
  },
  {
    id: 5,
    title: "Servizi",
    link: "/service",
    idItem: "service_submenu",
    submenu: services.map((s) => ({
      id: s.Id,
      title: s.title,
      link: `/service/${s.Id}`,
    })),
  },
  {
    id: 6,
    title: "Contattaci",
    link: "/contact",
    idItem: "contact_submenu",
    submenu: [
      {
        id: "61",
        title: "Contattaci",
        link: "/contact",
      },
      {
        id: "62",
        title: "Lavora con noi",
        link: "/hiring",
      },
    ],
  },
  {
    id: 7,
    title: "MOG-231/01",
    link: "",
    idItem: "MOG_submenu",
    target : 'blank',
    submenu: [
      {
        id: "31",
        title: "Aspetti generali",
        link: AspettiGeneraliPDF,
      },
      {
        id: "3222",
        title: "Parte speciale 1",
        link: parteSpecUnoPDF,
      },
      {
        id: "125",
        title: "Parte speciale 2",
        link: parteSpecduePDF,
      },
      {
        id: "1250",
        title: "Codice etico",
        link: codiceEticoPDF,
      },
    ],
  },
];
