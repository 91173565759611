import React, { useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";
import { ClickHandler } from '../../Utils/functions';
import { menuItems } from '../../api/menuItems.ts';

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);

    return (
        <ul className="main_menu_list clearfix">
            {menuItems.map((item, mn) => {
                return (
                    <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                        {item.submenu ?
                            <>
                                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                    <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                </p>
                                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                    <List className="subMenu">
                                        <>
                                            {item.submenu.map((submenu, i) => {
                                                return (
                                                    <ListItem key={i}>
                                                        {item.target === 'blank' ? <a onClick={ClickHandler} href={submenu.link} target="_blank" rel="noreferrer" className="active">{submenu.title}</a> : <Link onClick={ClickHandler} className="active" to={submenu.link}>{submenu.title}</Link>}
                                                    </ListItem>

                                                )
                                            })}
                                        </>
                                    </List>
                                </Collapse>
                            </>
                            : <Link className="active"
                                to={item.link}>{item.title}</Link>
                        }
                    </ListItem>
                )
            })}
        </ul>
    )
}

export default MobileMenu;