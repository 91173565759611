import React from 'react'
import { Link } from 'react-router-dom'
import Bg from '../../images/shapes/bg_pattern_3.svg'
import icon1 from '../../images/icons/icon_mail.svg'
import icon2 from '../../images/icons/icon_calling.svg'
import icon3 from '../../images/icons/icon_map_mark.svg'
import { ClickHandler } from '../../Utils/functions'
import { menuItems } from '../../api/menuItems.ts'
import { emailOspitaletto, nTelefonoOspitaletto } from '../../api/contattiSedi.js'

const Footer = () => {
    return (
        <footer className="site_footer footer_layout_1">
            <div className="content_box" style={{ backgroundImage: `url(${Bg})` }}>
                <div className="container">
                    <div className="diract_contact_links text-white">
                        <div className="iconbox_block layout_icon_left">
                            <div className="iconbox_icon">
                                <img src={icon1} alt="Mail SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Scrivici a </h3>
                                <p className="mb-0">
                                    {emailOspitaletto}
                                </p>
                            </div>
                        </div>
                        <div className="iconbox_block layout_icon_left">
                            <div className="iconbox_icon">
                                <img src={icon2} alt="Calling Check SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title"> Chiamaci al </h3>
                                <p className="mb-0">
                                    {nTelefonoOspitaletto}
                                </p>
                            </div>
                        </div>
                        <div className="iconbox_block layout_icon_left">
                            <div className="iconbox_icon">
                                <img src={icon3} alt="Map Mark Check SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Orari di apertura</h3>
                                <p className="mb-0">
                                    Lun-Ven: 08:00 - 18:00
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="footer_main_content">
                        <div className="row justify-content-lg-between">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer_widget">
                                    <h3 className="footer_info_title">Pagine</h3>
                                    <ul className="icon_list unordered_list_block">
                                        {menuItems.map((item, i) => (
                                            <li key={i}>
                                                <Link onClick={ClickHandler} to={item.link}>
                                                    <span className='icon_list_text'>
                                                        {item.title}
                                                    </span>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="footer_widget pe-lg-3">
                                    <h2 className="footer_info_title">TILog</h2>
                                    <p>
                                        Seguici anche sui social
                                    </p>
                                    <ul className="social_icons_block unordered_list">

                                        <li>
                                            <a onClick={ClickHandler} href="https://www.linkedin.com/company/tilog/mycompany/" target="_blank" rel="noopener noreferrer">
                                                <i className="fa-brands fa-linkedin-in"></i>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;