import ReactPlayer from 'react-player';
import video from '../../images/video/Video-Tilog.MP4'
import shape1 from '../../images/shapes/shape_space_1.svg'

export default function Video() {
    return (
        <>
            <div className="decoration_item shape_image_1 bg-light">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <section className='service_section team_details_section py-4 bg-light'>
                <div className="heading_block text-center">
                    <div className="heading_focus_text mb-0 d-inline-flex align-items-center" style={{ fontSize: '3em', fontWeight: 'bold' }}>
                        La nostra intelligenza artificiale <span className="me-3 badge bg-secondary text-white" style={{ fontSize: '1em' }}>Til.I.A</span>
                    </div>
                </div>
                <div className='justify-content-center' style={{ display: 'flex' }}>
                    <ReactPlayer controls url={video} className='h-100' />
                </div>
                <div className='container mt-5'>
                    <p style={{ wordBreak: 'break-all' }}>Scrivere qualcosa</p>
                </div>
            </section>
        </>
    )
}