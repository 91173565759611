interface ISettori {
  description: string;
}

export const settori: ISettori[] = [
  { description: "Healtcare" },
  { description: "Retail & Consumer" },
  { description: "Industrial & Chemical" },
  { description: "Logistica del dato" },
];
