import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import HiringSection from '../../components/HiringSection';
import ImageSection from '../../components/imageSection/ImageSection';
import hiringImg from '../../images/hiring/hiring.jpeg'


const Hiring = () => {

    return (
        <>
            <Header />
            <main className="page_content about-page">
                <ImageSection title='' img={hiringImg} />
                <HiringSection />
            </main>
            <Footer />
            <Scrollbar />
        </>
    )
}

export default Hiring;