interface IMansioni {
  id: number;
  mansione: string;
}

export const mansioni: IMansioni[] = [
  {
    id: 1,
    mansione: "Impiegato",
  },
  {
    id: 2,
    mansione: "Magazziniere",
  },
];
