
import { nTelefonoOspitaletto } from "./contattiSedi";
import { emailOspitaletto } from "./contattiSedi";

interface ISedi {
  Id: string;
  pImg: string;
  alt: string;
  title: string;
  telefono?: string;
  email?: string;
  indirizzo?: string;
}

const sedi: ISedi[] = [
  {
    Id: "1",
    pImg: '',
    alt: "Ospitaletto BS",
    title: "Ospitaletto",
    telefono: nTelefonoOspitaletto,
    email: emailOspitaletto,
    indirizzo: "Via Giovanni Falcone, 14, 25035 Ospitaletto BS",
  },
  {
    Id: "2",
    pImg: '',
    alt: "Alfianello BS",
    title: "Alfianello",
  },
  {
    Id: "3",
    pImg: '',
    alt: "Nova Milanese MB",
    title: "Nova Milanese",
  },
  {
    Id: "3",
    pImg: '',
    alt: "Concorezzo MB",
    title: "Concorezzo",

  },
  {
    Id: "3",
    pImg: '',
    alt: "Castel Guelfo BO",
    title: "Bologna zona industriale Castel Guelfo",
    email: emailOspitaletto,
    indirizzo: "Via San Carlo, 12/4, 40023 Castel Guelfo BO",
  },
];

export default sedi;
